export const cmds = {
  // General point commands
  DELETE_SINGLE_POINT: "DELETE_SINGLE_POINT",
  UPDATE_SINGLE_POINT: "UPDATE_SINGLE_POINT",

  // Delete point commands
  DELETE_COMMAND_AND_TARGET: "DELETE_COMMAND_AND_TARGET",
  DELETE_COMMAND: "DELETE_COMMAND",
  DELETE_MULTIPLE_POINTS: "DELETE_MULTIPLE_POINTS",

  // Merge points commands
  REMOVE_INVALID_H: "REMOVE_INVALID_H",
  MERGE_TWO_POINTS: "MERGE_TWO_POINTS",
  MERGE_MULTIPLE_POINTS: "MERGE_MULTIPLE_POINTS",

  // Line commands
  CREATE_LINE: "CREATE_LINE",
  LINEAR_SEGMENT: "LINEAR_SEGMENT",
  CREATE_MULTIPLE_LINES: "CREATE_MULTIPLE_LINES",
};
