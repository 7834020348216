import React from "react";

export const autoKroki_content = [
  {
    heading: "Какво е АвтоКроки?",
    content: (
      <div>
        Система за автоматично навързване на измервания. Чрез въвеждане на
        специфични кодове, алгоритъмът извършва сложни действия, като да чертае
        линии, да комбинира точки и да изтрива грешни данни.
      </div>
    ),
    show: false,
  },
  {
    heading: "Как се отваря системата?",
    content: (
      <div>
        След като точките бъдат трансформирани, ще излезе бутон с надпис
        АвтоКрокирай. Функцията не работи на мобилно устройство. Системата все
        още е в разработка. Предоставените функции работят правилно.
      </div>
    ),
    show: false,
  },
  {
    heading: "Как се запазват резултатите от обработката?",
    content: (
      <>
        <div>При отваряне на модула има два бутона.</div>
        <div>
          При натискане на "Copy current points", текущите точки се записват в
          клипборда.
        </div>
        <div>
          При натискане на "Copy AutoCAD commands", Аутокад команди за
          изчертаване на линиите, се записват в клипборда. След това могат да
          бъдат пейстнати директно в командния ред на Аутокад, за да се случи
          изчертаването или да бъдат записани в *.scr файл, който може да се
          отвори в Аутокад със същия ефект.
        </div>
      </>
    ),
    show: false,
  },
  {
    heading: "Как се отваря системата?",
    content: (
      <div>
        След като точките бъдат трансформирани, ще излезе бутон с надпис
        АвтоКрокирай. Функцията не работи на мобилно устройство.
      </div>
    ),
    show: false,
  },
  {
    heading: "Какви команди се подържат?",
    content: (
      <>
        <div>Автоматично навързване на линии.</div>
        <div>
          Комбиниране на координатите от една подробна точка и котата от друга.
        </div>
        <div>Изтриване на грешни измервания.</div>
        <div>
          В бъдеще се предвиждат и други командни кодове, но тези три обхващат
          голяма част от полезните сценарии.
        </div>
      </>
    ),
    show: false,
  },
  {
    heading: "Навързване на линия",
    content: (
      <>
        <div>
          Голяма част от последващата обработка на геодезически измервания е
          навързване на точки с полилиния. Това е трудоемко дори за малки
          обекти, но при полилинии с десетки ъгли, може да отнеме значително
          време. За тази цел точките, принадлежащи към една полилиния, могат да
          бъдат означени още при измерването им.
        </div>
        <div>
          При измерване на подробна точка се задава код, състоящ се от букви и
          накрая цифра. Например:
          <br />
          1&emsp;4784019.511&emsp;697153.521&emsp;123.190&emsp;asfalt1
          <br />
          2&emsp;4784013.749&emsp;697155.028&emsp;124.229&emsp;beton1
          <br />
          3&emsp;4784012.897&emsp;697152.617&emsp;124.192&emsp;ogr2
          <br />
          4&emsp;4784016.854&emsp;697154.391&emsp;123.691&emsp;a3
          <br />
        </div>
        <div>
          Всички подробни точки, които имат един и същ код (например ogr2), се
          навързват според реда на измеване в една полилиния.
        </div>
        <div>
          За да се прекъсне полилинията, след кода се добавя ".е". Например:
          <br />
          1&emsp;4784019.511&emsp;697153.521&emsp;123.190&emsp;asfalt1.е
          <br />
          1&emsp;4784019.511&emsp;697153.521&emsp;123.190&emsp;beton1.е
          <br />
          След като линията бъде прекъсната, кодът (например asfalt1) може да се
          използва за описване и измерване на нова линия.
        </div>
        <div>
          За да се начертае затворена полилиния, се добавя ".c". Например:
          <br />
          1&emsp;4784019.511&emsp;697153.521&emsp;123.190&emsp;asfalt1.c
          <br />
          1&emsp;4784019.511&emsp;697153.521&emsp;123.190&emsp;beton1.c
          <br />
          По този начин въпросната полилиния, ще бъде изчертана като затворен
          многоъгълник.
        </div>
        <div>
          Забележка: Не е необходимо ъглите на една полилиния да се измерват
          непосредствено една след друга. Следната последователност от
          измервания например, ще начертае две полилинии - една за ограда и една
          за бетонова настилка, плюс това има измерена шахта и кран:
          <br />
          1&emsp;4784019.511&emsp;697153.521&emsp;123.190&emsp;ogr1
          <br />
          2&emsp;4784013.749&emsp;697155.028&emsp;124.229&emsp;ogr1
          <br />
          3&emsp;4784012.897&emsp;697152.617&emsp;124.192&emsp;bet1
          <br />
          4&emsp;4784016.854&emsp;697154.391&emsp;123.691&emsp;ogr1
          <br />
          5&emsp;4784018.944&emsp;697150.745&emsp;123.083&emsp;sha
          <br />
          6&emsp;4784019.903&emsp;697152.779&emsp;123.088&emsp;bet1
          <br />
          7&emsp;4784025.873&emsp;697150.924&emsp;121.755&emsp;kran
          <br />
          8&emsp;4784025.113&emsp;697148.619&emsp;121.853&emsp;ogr1
          <br />
          9&emsp;4784035.156&emsp;697145.337&emsp;119.805&emsp;bet1.c
        </div>
      </>
    ),
    show: false,
  },
  {
    heading: "Комбиниране на точки",
    content: (
      <>
        <div>
          В много случаи е необходимо да се знаят и координатите и котата на
          дадена точка, но е невъзможно или неудобно да бъдат измерени
          едновременно. Това се случва например, при измерване с GNSS приемник,
          когато антената се вдигне над някакво препядствие. Измерената точка ще
          е с верни координати, но грешна кота.
        </div>
        <div>
          За такива случаи има предвидени няколко команди, чрез които
          потребителят може да измери две точки и АвтоКроки ще ги комбинира в
          една. При всички тези команди за код може да се пишат произволни
          символи, но кодът трябва да завършва със специфичната команда.
        </div>
        <div>
          Комбиниране на координатите от първата точка и котата от втората
          <br />
          За целта и двете точки се измерват последователно и с код, който
          завършва на ".xyh". Например:
          <br />
          1&emsp;4784019.511&emsp;697153.521&emsp;123.190&emsp;sgr.xyh
          <br />
          2&emsp;4784013.749&emsp;697155.028&emsp;124.229&emsp;sgr.xyh
          <br />
          <br />
          1&emsp;4784019.511&emsp;697153.521&emsp;123.190&emsp;ogr1.xyh
          <br />
          2&emsp;4784013.749&emsp;697155.028&emsp;124.229&emsp;ogr1.xyh
          <br />
          <br />
          Ако АвтоКроки срещне две последователни подробни точки, завършващи на
          ".xyh", автоматично ще ги комбинира, като вземе номерът, координатите
          и кода от първата точка и котата от втората точка. Ако програмата
          срещне само една точка, ще я презапише, но ще смени котата с -1000, с
          цел да означи, че тази точка няма валидна кота.
          <br />
          <br />
          Комбиниране на котата на първата точка и координатите на втората.
          <br />
          За целта и двете точки се измерват последователно и с код, който
          завършва на ".hxy". Например:
          <br />
          1&emsp;4784019.511&emsp;697153.521&emsp;123.190&emsp;sgr.hxy
          <br />
          2&emsp;4784013.749&emsp;697155.028&emsp;124.229&emsp;sgr.hxy
          <br />
          <br />
          1&emsp;4784019.511&emsp;697153.521&emsp;123.190&emsp;ogr1.hxy
          <br />
          2&emsp;4784013.749&emsp;697155.028&emsp;124.229&emsp;ogr1.hxy
          <br />
          <br />
          Ако АвтоКроки срещне две последователни подробни точки, завършващи на
          ".hxy", автоматично ще ги комбинира, като вземе номерът, котата и кода
          от първата точка и координатите от втората точка. Ако програмата
          срещне само една точка, ще я пренебрегне кода.
          <br />
          Измерване на точка без кота.
          <br />
          За целта подробната точка се измерва с код, завършващ на ".xy".
          Например:
          <br />
          1&emsp;4784019.511&emsp;697153.521&emsp;123.190&emsp;sgr.xy
          <br />
          2&emsp;4784013.749&emsp;697155.028&emsp;124.229&emsp;sgr.xy
          <br />
          <br />
          1&emsp;4784019.511&emsp;697153.521&emsp;123.190&emsp;ogr1.xy
          <br />
          2&emsp;4784013.749&emsp;697155.028&emsp;124.229&emsp;ogr1.xy
          <br />
          <br />
          Ако АвтоКроки срещне точка с тази команда, ще промени котата й да е
          -1000, за да означи, че не е валидна.
        </div>
      </>
    ),
    show: false,
  },
  {
    heading: "Изтриване на грешни точки",
    content: (
      <>
        <div>
          Понякога се случва да се направи грешно измерване, което трябва да
          бъде изтрито в последващата обработка. Когато обектът е малък, това не
          е проблем, но при големи обекти става сложно да се следят грешните
          измервания и расте рискът от пропуски.
        </div>
        <div>
          За да бъде изтрита предишната точка, се измерва нова подробна точка с
          код ".del.p". Когато АвтоКроки срещне този код, автоматично ще изтрие
          командната точка и предната грешна точка.
        </div>
        <div>
          За да бъде изтрита най-близката по разстояние точка, се измерва нова
          продробна точка с код ".del.n". Когато АвтоКроки срещне този код,
          автоматично ще изтрие командната точка и най-близката до нея точка.
        </div>
      </>
    ),
    show: false,
  },
];
