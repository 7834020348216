import React from "react";

export const FAQ_content = [
  {
    heading: "Каква е целта на този сайт?",
    content: (
      <div>
        Този сайт цели да вземе най-използваните елементи от официалната
        програма на кадастъра БГСТранс и да ги направи достъпни навсякъде,
        независимо от устройството и операционната система.
      </div>
    ),
    show: false,
  },
  {
    heading: "Как се въвеждат данни?",
    content: (
      <React.Fragment>
        <div>Има няколко начина за въвеждане на данни за трансформация.</div>
        <div>
          Могат директно да се пишат или пействат в текстовото поле в секция
          "Входни координати". Очакваните формати са следните:
          <br />
          № X Y<br />
          № X Y H<br />
          № X Y H Код
          <br />
          След Код може да има неограничен брой полета без специфично
          значение, които ще бъдат директно прехвърлени към крайния резултат.
          <br />
          За разделител могат да се използват Табулация, Интервал, Запетая или
          Вертикална черта. Кои точно разделители са активни, се настройва от
          бутон "Разделители".
        </div>
        <div>
          Данни могат да се въвеждат и чрез текстов файл. Файлът може да се
          отвори чрез бутон "Отвори файл", а също така може да бъде провлачен
          с мишката и пуснат върху текстовото поле.
        </div>
        <div>
          Сайтът може да зарежда и трансформира DXF файлове. DXF
          файл се отваря отново чрез бутон "Отвори файл", а също така може да
          бъде провлачен с мишката и пуснат върху текстовото поле.
        </div>
      </React.Fragment>
    ),
    show: false,
  },
  {
    heading: "Как работи координатната трансформация?",
    content: (
      <React.Fragment>
        <div>
          Координатните трансформации се осъществяват чрез полиномна
          трансформация от трети ред, използвайки формулите от Приложение № 11
          към чл. 26 и чл. 27 от Инструкция № РД-02-20-12 от 03 август 2012 г.
          за преобразуване на съществуващите геодезически и картографски
          материали и данни в „Българска геодезическа система 2005“.
        </div>
        <div>
          За извеждане на параметрите на трансформациите е използвана
          регулярна мрежа от точки, покриваща територията на България.
          Координатите на точките са трансформирани чрез БГС Транс(версия
          4.6). Коефициентите на полиномите са определени чрез метод на
          най-малките квадрати, изхождайки от трансформираните точки.
        </div>
      </React.Fragment>
    ),
    show: false,
  },
  {
    heading: "Каква е точността на получените координатни трансформации?",
    content: (
      <React.Fragment>
        <div>
          Оценката на точността е направена на база на регулярно разпределени
          през 5км точки, трансформирани чрез този сайт и чрез БГСТранс(версия
          4.6). При изчисляване на точностите се приема, че резултатът от
          БГСТранс е истинската стойност на съответната трансформация.
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th>От</th>
                <th>Към</th>
                <th>n</th>
                <th>
                  m<sub>x</sub>
                </th>
                <th>
                  m<sub>y</sub>
                </th>
                <th>
                  f<sub>x</sub>
                  <sup>max</sup>
                </th>
                <th>
                  f<sub>y</sub>
                  <sup>max</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>БГС 2005, кадастрална</td>
                <td>КС 1970, К3</td>
                <td>1242</td>
                <td>0.1mm</td>
                <td>0.1mm</td>
                <td>1mm</td>
                <td>1mm</td>
              </tr>
              <tr>
                <td>БГС 2005, кадастрална</td>
                <td>КС 1970, К5</td>
                <td>1433</td>
                <td>0.2mm</td>
                <td>0.2mm</td>
                <td>1mm</td>
                <td>1mm</td>
              </tr>
              <tr>
                <td>БГС 2005, кадастрална</td>
                <td>КС 1970, К7</td>
                <td>1445</td>
                <td>0.4mm</td>
                <td>0.4mm</td>
                <td>1mm</td>
                <td>1mm</td>
              </tr>
              <tr>
                <td>БГС 2005, кадастрална</td>
                <td>КС 1970, К9</td>
                <td>1589</td>
                <td>0.1mm</td>
                <td>0.2mm</td>
                <td>1mm</td>
                <td>1mm</td>
              </tr>
              <tr>
                <td>КС 1970, К3</td>
                <td>БГС 2005, кадастрална</td>
                <td>1242</td>
                <td>0.2mm</td>
                <td>0.2mm</td>
                <td>1mm</td>
                <td>1mm</td>
              </tr>
              <tr>
                <td>КС 1970, К5</td>
                <td>БГС 2005, кадастрална</td>
                <td>1433</td>
                <td>0.2mm</td>
                <td>0.2mm</td>
                <td>1mm</td>
                <td>1mm</td>
              </tr>
              <tr>
                <td>КС 1970, К7</td>
                <td>БГС 2005, кадастрална</td>
                <td>1445</td>
                <td>0.4mm</td>
                <td>0.4mm</td>
                <td>1mm</td>
                <td>2mm</td>
              </tr>
              <tr>
                <td>КС 1970, К9</td>
                <td>БГС 2005, кадастрална</td>
                <td>1589</td>
                <td>0.2mm</td>
                <td>0.2mm</td>
                <td>1mm</td>
                <td>1mm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    ),
    show: false,
  },
  {
    heading: "Как работи височинната трансформация",
    content: (
      <React.Fragment>
        <div>
          Височинната трансформация има два аспекта - коригиране с ондулацията
          на квазигеоида и трансформация между нормални височинни системи.
        </div>
        <div>
          За определяне на разликата между геоида и квазигеоида е създадена
          регулярна мрежа от точки, покриваща територията на България.
          Изчислени са стойностите на ондулацията за всяка от точките,
          използвайки БГСТранс(версия 4.6). За изчисляване на ондулацията на
          произволна точка, се използва билинейна интерполация, на база на
          стойностите на върховете на заграждащия квадрат.
        </div>
        <div>
          За трансформиране между нормални височини в Балтийска височинна
          система и EVRS 2007, се изхожда от формулите в Приложение № 19 към
          чл. 30 от Инструкция № РД-02-20-12 от 03 август 2012 г. за
          преобразуване на съществуващите геодезически и картографски
          материали и данни в „Българска геодезическа система 2005“.
        </div>
      </React.Fragment>
    ),
    show: false,
  },
  {
    heading: "Каква е точността на получените височинни трансформации?",
    content: (
      <React.Fragment>
        <div>
          Оценката на точността е направена на база на регулярно разпределени
          през 5км точки, трансформирани чрез този сайт и чрез БГСТранс(версия
          4.6). При изчисляване на точностите се приема, че резултатът от
          БГСТранс е истинската стойност на съответната трансформация.
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th>От</th>
                <th>Към</th>
                <th>n</th>
                <th>
                  m<sub>h</sub>
                </th>
                <th>
                  f<sub>h</sub>
                  <sup>max</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Геодезични височини</td>
                <td>EVRS 2007</td>
                <td>5258</td>
                <td>0.3mm</td>
                <td>1mm</td>
              </tr>
              <tr>
                <td>Геодезични височини</td>
                <td>Балтийска</td>
                <td>5258</td>
                <td>0.3mm</td>
                <td>1mm</td>
              </tr>
              <tr>
                <td>EVRS 2007</td>
                <td>Балтийска</td>
                <td>5258</td>
                <td>0.2mm</td>
                <td>1mm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    ),
    show: false,
  },
];